
class Url
{
	static param(param:string, defValue?:string):string
	{
	    var url = window.location.search.substring(1);
	    var variables = url.split('&');
	    for (var i = 0; i < variables.length; i++) 
	    {
	        var tokens = variables[i].split('=');
	        if (tokens[0] == param && tokens.length>1) 
	        {
	            return tokens[1];
	        }
	    }
		return defValue;
	}    
	static pathTokens():string[]
	{
		var url = location.href;
		var posQ = url.indexOf("?");
		if (posQ>0) url = url.substring(0,posQ);
		var posH = url.indexOf("#");
		if (posH>0) url = url.substring(0,posH);
		return url.split("/");
	}
	static protocol():string { return Url.pathTokens()[0].replace(":",""); }
	static domain():string { return Url.pathTokens()[2]; }
	static root():string { return Url.pathTokens()[3]; }
	static action():string { return Url.pathTokens()[4]; }
	static page():string { return Url.pathTokens()[4]; }
	static last():string { return Url.pathTokens()[Url.pathTokens().length-1]; }

	static hash():string
	{
		var hash:string = location.hash;
		if (hash.length>1) return hash.substring(1);
		return null
	}
	static hashTokens():string[]
	{
		var hash = Url.hash();
		if (hash==null) return [];
		return Url.hash().split("/");
	}
	static withoutHashAndQuery():string
	{
		let s:string = location.href;
		let pos = s.indexOf("#");
		if (pos>=0) s = s.substring(0, pos);
		pos = s.indexOf("?");
		if (pos>=0) s = s.substring(0, pos);
		return s;
	}
}
